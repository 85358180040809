<template>
  <div class="card">
    <div class="card-header px-2 pb-0">
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="base.search.text"
            placeholder="Please input"
            clearable
            class="input-with-select"
            @keyup.enter="onSearch"
          >
            <template #prepend>
              <el-select
                v-model="base.search.key"
                placeholder="Select"
                style="width: 125px"
              >
                <el-option label="Email" value="email" />
                <el-option label="Role" value="defaultRole" />
                <el-option label="Gender" value="gender" />
                <el-option label="Address" value="address" />
              </el-select>
            </template>
            <template #append>
              <el-button :icon="Search" @click="onSearch" />
            </template>
          </el-input>
        </el-col>
        <el-col :span="12" class="pr20">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddUser"
            >User</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <!-- <div class="table-responsive p-0 table-max-height"> -->
      <el-table
        ref="tableRef"
        class="table-max-height"
        row-key="email"
        header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
        row-class-name="text-secondary text-xs font-weight-bold"
        :data="base.data.records"
        style="width: 100%"
      >
        <el-table-column label="USER" width="275">
          <template #default="scope">
            <div v-if="scope" class="d-flex py-1">
              <div>
                <img
                  src="../../assets/img/team-2.jpg"
                  class="avatar avatar-sm me-3"
                  alt="user1"
                />
              </div>
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-sm">
                  {{ scope.row.firstName + ' ' + scope.row.lastName }}
                </h6>
                <p class="text-xs text-secondary mb-0">
                  {{ scope.row.email }}
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="enabled" label="enabled?" width="100">
          <template #default="scope">
            <span v-if="scope.row.enabled" class="badge bg-gradient-success"
              >Yes</span
            >
            <span v-else class="badge bg-gradient-secondary">No</span>
          </template>
        </el-table-column>
        <el-table-column prop="defaultRole" label="Role" width="100" />
        <el-table-column prop="gender" label="Gender" width="100" />
        <el-table-column prop="address" label="Address" width="250" />
        <el-table-column
          prop="isPlatformAdministrator"
          label="Is PlatformAdministrator?"
          width="225"
        >
          <template #default="scope">
            <span
              v-if="scope.row.isPlatformAdministrator"
              class="badge bg-gradient-success"
              >Yes</span
            >
            <span v-else class="badge bg-gradient-secondary">No</span>
          </template>
        </el-table-column>
        <el-table-column label="" min-width="75">
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              @click="handleEditClick(scope.row)"
              >Edit</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="base.data.cursor.currentPage"
        small
        class="m-t-10 mb2"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="base.data.cursor.perPage"
        :page-sizes="base.pageSizes"
        :total="parseInt(base.data.cursor.totalRecords)"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      v-model="base.userFormVisible"
      title="User Information"
      width="90%"
      destroy-on-close
      align-center
    >
      <el-form :model="base.userForm" label-position="top">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Title">
              <el-select
                v-model="base.userForm.title"
                placeholder="Select"
                size="large"
              >
                <el-option
                  v-for="item in base.titleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="First Name">
              <el-input
                v-model="base.userForm.firstName"
                size="large"
                autocomplete="off"
                placeholder="First Name"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Middle Name">
              <el-input
                v-model="base.userForm.middleName"
                size="large"
                placeholder="Middle Name"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Last Name">
              <el-input
                v-model="base.userForm.lastName"
                size="large"
                placeholder="Last Name"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Email">
              <el-input
                v-model="base.userForm.email"
                size="large"
                placeholder="Email"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Password">
              <el-input
                v-model="base.userForm.password"
                class="login-input"
                size="large"
                autocomplete="off"
                type="password"
                placeholder="Password"
                show-password
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Gender">
              <el-select
                v-model="base.userForm.gender"
                placeholder="Select"
                size="large"
              >
                <el-option
                  v-for="item in base.genderOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Mobile Number">
              <el-input
                v-model="base.userForm.mobileNumber"
                size="large"
                autocomplete="off"
                placeholder="Mobile Number"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Alternate Number">
              <el-input
                v-model="base.userForm.alternateNumber"
                size="large"
                autocomplete="off"
                placeholder="Alternate Number"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Address">
              <el-input
                v-model="base.userForm.address"
                size="large"
                autocomplete="off"
                placeholder="Address"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Country">
              <el-select
                v-model="base.userForm.country"
                placeholder="Select Country"
                size="large"
                value-key="country"
                filterable
                @change="handleSetStates"
              >
                <el-option
                  v-for="item in base.countries"
                  :key="item.country"
                  :label="item.country"
                  :value="item.country"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="State">
              <el-select
                v-model="base.userForm.state"
                placeholder="Select State"
                size="large"
                filterable
              >
                <el-option
                  v-for="item in base.states"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="City">
              <el-input
                v-model="base.userForm.city"
                size="large"
                autocomplete="off"
                placeholder="City"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="ZIP Code">
              <el-input
                v-model="base.userForm.zipCode"
                size="large"
                autocomplete="off"
                placeholder="ZIP Code"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="base.userFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="handleSaveClick">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import { Search, Plus } from '@element-plus/icons-vue'
import constants from '@/constants'
import UsersSvc from '@/services/user'
// import RolesSvc from '@/services/role'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import _ from 'lodash'

export default {
  name: 'UsersTabe',
  setup() {
    const base = reactive({
      userFormVisible: false,
      search: {
        text: '',
        key: 'email',
      },
      userForm: {
        email: '',
        password: '',
        title: '',
        firstName: '',
        lastName: '',
        middleName: '',
        gender: '',
        roles: [],
        defaultRole: '',
        mobileNumber: '',
        alternateNumber: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        isPlatformAdministrator: false,
      },
      data: {
        records: [],
        cursor: { currentPage: 0, perPage: 0, totalRecords: 0 },
      },
      titleOptions: constants.TITLE_OPTIONS,
      genderOptions: constants.GENDER_OPTIONS,
      countries: constants.COUNTRIES,
      states: [],
      roles: [],
      pageSizes: constants.DEFAULT_PAGE_SIZES,
    })

    const local = {
      currentQuery: undefined,
      sort: {},
      fields: undefined,
    }

    onMounted(() => {
      fetchUsers()
      // fetchRoles()
      handleSetStates()
    })

    const onSearch = () => {
      let q = local.currentQuery
      if (base.search.text) {
        q = {}
        local.currentPage = 1
        // if (searchKeyType === 'date') {
        //   q[searchKey] = {
        //     $gte: QueryBuilder.getDate(searchText, 1),
        //     $lt: QueryBuilder.getDate(searchText, 2)
        //   }
        // } else {
        //   q[searchKey] = { $regex: '.*' + searchText + '.*', $options: 'i' }
        // }
        q[base.search.key] = {
          $regex: '.*' + base.search.text + '.*',
          $options: 'i',
        }
      } else {
        q = {}
      }
      if (!_.isEqual(q, local.currentQuery)) {
        local.currentQuery = q
        fetchUsers(
          local.currentQuery,
          local.fields,
          base.data.cursor.currentPage,
          base.data.cursor.perPage,
          local.sort,
        )
      }
    }

    // const fetchRoles = () => {
    //   const options = AuthSvc.getOptions()
    //   RolesSvc.find(options)
    //     .then((response) => {
    //       base.roles = response.data.records
    //       console.log('base.roles', base.roles)
    //     })
    //     .catch((error) => {
    //       console.log('UsersTable fetch error => ', error)
    //       common.showErrors(error)
    //     })
    // }

    const fetchUsers = (query, projection, page, limit, sort) => {
      const options = AuthSvc.getOptions()
      UsersSvc.find(options, query, projection, page, limit, sort)
        .then((response) => {
          base.data = response.data
        })
        .catch((error) => {
          console.log('UsersTable fetch error => ', error)
          common.showErrors(error)
        })
    }

    const handleCreateNewUser = () => {
      return {
        email: '',
        password: '',
        title: '',
        firstName: '',
        lastName: '',
        middleName: '',
        gender: '',
        roles: ['Superuser'],
        defaultRole: 'Superuser',
        mobileNumber: '',
        alternateNumber: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        isPlatformAdministrator: false,
      }
    }

    const handleAddUser = () => {
      base.userForm = handleCreateNewUser()
      base.userFormVisible = true
    }

    const handleEditClick = (user) => {
      const options = AuthSvc.getOptions()
      UsersSvc.findById(options, user._id)
        .then((response) => {
          base.userForm = response.data
          base.userFormVisible = true
        })
        .catch((error) => {
          console.log('UsersTable handleEditClick error => ', error)
          common.showErrors(error)
        })
    }

    const handleSetStates = () => {
      if (base.userForm.country) {
        const country = constants.COUNTRIES.find(
          (f) => f.country === base.userForm.country,
        )
        base.states = country?.states || []
      } else {
        base.states = []
      }
    }

    const handleSaveClick = () => {
      const options = AuthSvc.getOptions()
      console.log(JSON.stringify(base.userForm))
      base.userForm.login = base.userForm.email
      UsersSvc.save(options, base.userForm)
        .then(async (response) => {
          console.log('UsersTable handleSaveClick response ==>', response.data)
          fetchUsers()
          base.userFormVisible = false
        })
        .catch((error) => {
          console.log('UsersTable handleSaveClick error => ', error)
          common.showErrors(error)
        })
    }

    const handleCurrentPageChange = (val) => {
      base.data.cursor.currentPage = val
      fetchUsers(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort,
      )
    }

    const handlePageSizeChange = (val) => {
      base.data.cursor.perPage = val
      fetchUsers(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort,
      )
    }

    return {
      base,
      Search,
      Plus,
      handleSetStates,
      handleEditClick,
      handleAddUser,
      handleSaveClick,
      handleCurrentPageChange,
      handlePageSizeChange,
      onSearch,
    }
  },
}
</script>

<style>
.table-max-height {
  min-height: calc(100vh - 205px);
  max-height: calc(100vh - 205px);
}
</style>
