const constants = require('../constants')
const moment = require('moment')

const isWeekend = (date) => {
  const day = date.day()
  return day === 0 || day === 6 // 0 is Sunday, 6 is Saturday
}

// Function to generate dates array for 30 days by default from today
const generateDatesArray = (days = 30, fromDate = null) => {
  const dateArray = []
  const today = fromDate ? moment(fromDate) : moment().startOf('day')

  for (let i = 0; i < days; i++) {
    const futureDate = today.clone().add(i, 'days')
    const dayNumber = futureDate.date()
    const dayOfWeek = futureDate.format('ddd') // First letter of day of the week
    const formattedDate = `${dayNumber} ${dayOfWeek}`
    const monthNumber = futureDate.month() + 1
    const datePayload = {
      formattedDate,
      date: futureDate.toISOString(),
      isWeekend: isWeekend(futureDate),
      month: constants.MONTHS[monthNumber],
    }
    dateArray.push(datePayload)
  }

  return dateArray
}

const getLast7Dates = () => {
  const dates = []
  const today = moment().startOf('day')
  for (let i = 0; i < 7; i++) {
    dates.unshift(today.clone().subtract(i, 'days').format('D-MMM-YYYY'))
  }
  return dates
}

const getShortDateFormat = (value) => {
  const date = moment(value)
  return date.format('DD-MMMM-YYYY')
}

module.exports = {
  generateDatesArray,
  getLast7Dates,
  getShortDateFormat,
}
// Generate dates array and print it
// const datesArray = generateDatesArray()
// console.log(datesArray)
