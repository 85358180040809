<script setup>
import { onBeforeMount } from 'vue'
import calendarUtil from '@/utils/calendarUtil'
import MiniStatisticsCard from '@/examples/Cards/MiniStatisticsCard.vue'
import GradientLineChart from '@/examples/Charts/GradientLineChart.vue'
// import Carousel from "./components/Carousel.vue";
// import CategoriesList from "./components/CategoriesList.vue";

// import US from "@/assets/img/icons/flags/US.png";
// import DE from "@/assets/img/icons/flags/DE.png";
// import GB from "@/assets/img/icons/flags/GB.png";
// import BR from "@/assets/img/icons/flags/BR.png";
let dates = []
onBeforeMount(() => {
  dates = calendarUtil.getLast7Dates()
  console.log('dates', dates)
})

// const sales = {
//   us: {
//     country: "United States",
//     sales: 2500,
//     value: "$230,900",
//     bounce: "29.9%",
//     flag: US,
//   },
//   germany: {
//     country: "Germany",
//     sales: "3.900",
//     value: "$440,000",
//     bounce: "40.22%",
//     flag: DE,
//   },
//   britain: {
//     country: "Great Britain",
//     sales: "1.400",
//     value: "$190,700",
//     bounce: "23.44%",
//     flag: GB,
//   },
//   brasil: {
//     country: "Brasil",
//     sales: "562",
//     value: "$143,960",
//     bounce: "32.14%",
//     flag: BR,
//   },
// };
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Today's Revenue"
              value="₹53,000"
              description="<span
                class='text-sm font-weight-bolder text-green'
                >+10%</span> since yesterday"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Today's Occupancy"
              value="8"
              description="<span
                class='text-sm font-weight-bolder text-danger'
                >-2%</span> since yesterday"
              :icon="{
                component: 'ni ni-world',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Available Rooms"
              value="4"
              description="<span
                class='text-sm font-weight-bolder text-green'
                >-2%</span> since yesterday"
              :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <!-- ni ni-cart -->
            <mini-statistics-card
              title="Staff Absent Today"
              value="1"
              description="<span
                class='text-sm font-weight-bolder text-green'
                >20</span> Staff Present Today"
              :icon="{
                component: 'ni ni-single-02',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <!-- <i class='fa fa-arrow-up text-green'></i>
      <span class='font-weight-bold'>4% more</span>  -->
              <gradient-line-chart
                id="chart-line"
                title="Revenue Overview"
                description="Last Week Day wise Revenue"
                :chart="{
                  labels: dates,
                  datasets: [
                    {
                      label: 'Revenue',
                      data: [
                        10000, 40000, 35000, 22000, 50000, 25000, 45000, 23000,
                        50000,
                      ],
                    },
                  ],
                }"
              />
            </div>
          </div>
          <div class="col-lg-5">
            <div class="card z-index-2">
              <gradient-line-chart
                id="chart-line1"
                title="Revenue Monthly Overview"
                description="Month on month revenue change"
                :chart="{
                  labels: [
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ],
                  datasets: [
                    {
                      label: 'Revenue',
                      data: [
                        10000, 40000, 35000, 22000, 50000, 25000, 45000, 23000,
                        50000,
                      ],
                    },
                  ],
                }"
              />
            </div>
            <!-- <carousel /> -->
          </div>
        </div>
        <!-- <div class="row mt-4">
          <div class="col-lg-7 mb-lg-0 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">Sales by Country</h6>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table align-items-center">
                  <tbody>
                    <tr v-for="(sale, index) in sales" :key="index">
                      <td class="w-30">
                        <div class="px-2 py-1 d-flex align-items-center">
                          <div>
                            <img :src="sale.flag" alt="Country flag" />
                          </div>
                          <div class="ms-4">
                            <p class="mb-0 text-xs font-weight-bold">
                              Country:
                            </p>
                            <h6 class="mb-0 text-sm">{{ sale.country }}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Sales:</p>
                          <h6 class="mb-0 text-sm">{{ sale.sales }}</h6>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Value:</p>
                          <h6 class="mb-0 text-sm">{{ sale.value }}</h6>
                        </div>
                      </td>
                      <td class="text-sm align-middle">
                        <div class="text-center col">
                          <p class="mb-0 text-xs font-weight-bold">Bounce:</p>
                          <h6 class="mb-0 text-sm">{{ sale.bounce }}</h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <categories-list
              :categories="[
                {
                  icon: {
                    component: 'ni ni-mobile-button',
                    background: 'dark',
                  },
                  label: 'Devices',
                  description: '250 in stock <strong>346+ sold</strong>',
                },
                {
                  icon: {
                    component: 'ni ni-tag',
                    background: 'dark',
                  },
                  label: 'Tickets',
                  description: '123 closed <strong>15 open</strong>',
                },
                {
                  icon: { component: 'ni ni-box-2', background: 'dark' },
                  label: 'Error logs',
                  description: '1 is active <strong>40 closed</strong>',
                },
                {
                  icon: { component: 'ni ni-satisfied', background: 'dark' },
                  label: 'Happy Users',
                  description: '+ 430',
                },
              ]"
            />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
