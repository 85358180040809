import { createRouter, createWebHistory } from 'vue-router'
import _ from 'lodash'
import AuthSvc from '@/services/auth'
import LocalStorageSvc from '@/services/localStorage'

import Dashboard from '../views/Dashboard.vue'
import Tables from '../views/Tables.vue'
import Billing from '../views/Billing.vue'
import VirtualReality from '../views/VirtualReality.vue'
import RTL from '../views/Rtl.vue'
import Profile from '../views/Profile.vue'
import Signup from '../views/Signup.vue'
import Signin from '../views/Signin.vue'
import Rooms from '../views/Rooms.vue'
import RoomTypes from '../views/RoomTypes.vue'
import Reservations from '../views/Reservations.vue'
import MenuItems from '../views/MenuItems.vue'
import FNBInvoices from '../views/FNBInvoices.vue'
import StaffDetails from '../views/StaffDetails.vue'
import Subscriptions from '../views/Subscriptions.vue'
import HotelDetails from '../views/HotelDetails.vue'
import StaffAttendance from '../views/StaffAttendance.vue'

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/signin',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/users',
    name: 'Users',
    component: Tables,
  },
  {
    path: '/rooms',
    name: 'Rooms',
    component: Rooms,
  },
  {
    path: '/roomTypes',
    name: 'Room Types',
    component: RoomTypes,
  },
  {
    path: '/menuItems',
    name: 'Menu Items',
    component: MenuItems,
  },
  {
    path: '/reservations',
    name: 'Reservations',
    component: Reservations,
  },
  {
    path: '/fnbInvoices',
    name: 'FNB Invoices',
    component: FNBInvoices,
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
  },
  {
    path: '/virtual-reality',
    name: 'Virtual Reality',
    component: VirtualReality,
  },
  {
    path: '/rtl-page',
    name: 'RTL',
    component: RTL,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/staffDetails',
    name: 'Staff Details',
    component: StaffDetails,
  },
  {
    path: '/staffAttendance',
    name: 'Staff Attendance',
    component: StaffAttendance,
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions,
  },
  {
    path: '/hotelDetails',
    name: 'Hotel Details',
    component: HotelDetails,
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
})

router.beforeEach((to, from, next) => {
  console.log('to.path', to.path)
  if (to.path === '/' || to.path === '/signin') {
    const token = AuthSvc.getJWTToken()
    const jwtExpiry = AuthSvc.getTokenExpiry()
    const currentTime = Date.now().valueOf() / 1000
    if (!_.isEmpty(token) && jwtExpiry > currentTime) {
      router.push('/dashboard')
    } else {
      LocalStorageSvc.clear()
      next()
    }
  } else {
    next()
  }
})

export default router
