const axios = require('axios')
const sessionTimeout = require('../utils/sessionTimeout')

const getClient = (options) => {
  const client = axios.create(options)
  const authToken = options?.authToken
    ? options.authToken.indexOf('Bearer ') < 0
      ? `Bearer ${options.authToken}`
      : options.authToken
    : ''
  client.defaults.headers.common = {}
  client.defaults.headers.common.Authorization = authToken
  client.defaults.headers.common['x-role'] = options?.role ? options.role : ''
  if (client.interceptors) {
    client.interceptors.response.use(
      sessionTimeout.onResponse,
      sessionTimeout.onError,
    )
  }
  return client
}

module.exports = {
  getClient,
}
