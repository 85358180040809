<template>
  <div class="card">
    <div class="card-header px-2 pb-0">
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="base.search.text"
            placeholder="Please input"
            clearable
            class="input-with-select"
            @keyup.enter="onSearch"
          >
            <template #prepend>
              <el-select
                v-model="base.search.key"
                placeholder="Select"
                style="width: 125px"
              >
                <el-option label="Staff Id" value="staffId" />
                <el-option label="First Name" value="firstName" />
                <el-option label="Last Name" value="lastName" />
                <el-option label="Email" value="email" />
                <el-option label="Position" value="position" />
              </el-select>
            </template>
            <template #append>
              <el-button :icon="Search" @click="onSearch" />
            </template>
          </el-input>
        </el-col>
        <el-col :span="12" class="pr20">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddStaffDetails"
            >Staff Details</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-table
        ref="tableRef"
        class="table-max-height"
        row-key="email"
        header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
        row-class-name="text-secondary text-xs font-weight-bold"
        :data="base.data.records"
        style="width: 100%"
      >
        <el-table-column prop="staffId" label="Staff Id" />
        <el-table-column label="First Name" prop="firstName" />
        <el-table-column label="Last Name" prop="lastName" />
        <el-table-column label="Email" prop="email" />
        <el-table-column label="Phone Number" prop="phoneNumber" />
        <el-table-column label="Position" prop="position" />
        <el-table-column label="" min-width="75">
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              @click="handleEditClick(scope.row)"
              >Edit</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="base.data.cursor.currentPage"
        small
        class="m-t-10 mb2"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="base.data.cursor.perPage"
        :page-sizes="base.pageSizes"
        :total="parseInt(base.data.cursor.totalRecords)"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      v-model="base.staffDetailsVisible"
      title="Staff Details"
      width="70%"
      destroy-on-close
      align-center
    >
      <el-form
        ref="staffDetailsRef"
        :model="base.staffDetailsForm"
        label-position="top"
        :rules="base.staffDetailsRules"
      >
        <el-row :gutter="20" class="mt-2">
          <el-col :span="8">
            <el-form-item label="Staff Id" prop="staffId">
              <el-input
                v-model="base.staffDetailsForm.staffId"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="First Name" prop="firstName">
              <el-input
                v-model="base.staffDetailsForm.firstName"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Last Name" prop="lastName">
              <el-input
                v-model="base.staffDetailsForm.lastName"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Email" prop="email">
              <el-input
                v-model="base.staffDetailsForm.email"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Position" prop="position">
              <el-input
                v-model="base.staffDetailsForm.position"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Salary">
              <el-input-number
                v-model="base.staffDetailsForm.salary"
                :min="0"
                :max="1"
                class="w-100"
                controls-position="right"
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Date Of Joining" prop="dateOfJoining">
              <el-date-picker
                v-model="base.staffDetailsForm.dateOfJoining"
                type="date"
                placeholder="Pick a day"
                size="large"
                class="w-100"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <hr class="horizontal dark" />
        <p class="text-uppercase text-sm">Contact Information</p>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Address">
              <el-input
                v-model="base.staffDetailsForm.address"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Country">
              <el-select
                v-model="base.staffDetailsForm.country"
                placeholder="Select"
                size="large"
                value-key="country"
                @change="handleSetStates"
              >
                <el-option
                  v-for="item in base.countries"
                  :key="item.country"
                  :label="item.country"
                  :value="item.country"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="State">
              <el-select
                v-model="base.staffDetailsForm.state"
                placeholder="Select"
                size="large"
              >
                <el-option
                  v-for="item in base.states"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="City">
              <el-input
                v-model="base.staffDetailsForm.city"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="ZIP Code">
              <el-input
                v-model="base.staffDetailsForm.zipCode"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Phone Number">
              <el-input
                v-model="base.staffDetailsForm.phoneNumber"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="base.staffDetailsVisible = false"
            >Cancel</el-button
          >
          <el-button type="primary" @click="handleSave"> Confirm </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { Search, Plus } from '@element-plus/icons-vue'
import StaffDetailSvc from '@/services/staffDetail'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import { ElNotification } from 'element-plus'
import constants from '@/constants'
import _ from 'lodash'

export default {
  name: 'StaffDetailsTable',
  setup() {
    const staffDetailsRef = ref(null)
    const base = reactive({
      staffDetailsVisible: false,
      search: {
        text: '',
        key: 'staffId',
      },
      staffDetailsForm: {},
      data: {
        records: [],
        cursor: { currentPage: 0, perPage: 0, totalRecords: 0 },
      },
      pageSizes: constants.DEFAULT_PAGE_SIZES,
      staffDetailsRules: {
        staffId: [{ required: true, message: ' ', trigger: 'blur' }],
        firstName: [{ required: true, message: ' ', trigger: 'blur' }],
        lastName: [{ required: true, message: ' ', trigger: 'blur' }],
        email: [
          { required: true, message: ' ', trigger: 'blur' },
          { type: 'email', message: ' ', trigger: 'blur' },
        ],
        position: [{ required: true, message: ' ', trigger: 'blur' }],
        dateOfJoining: [{ required: true, message: ' ', trigger: 'blur' }],
      },
    })

    const local = {
      currentQuery: undefined,
      sort: {},
      fields: undefined,
    }

    const onSearch = () => {
      let q = local.currentQuery
      if (base.search.text) {
        q = {}
        local.currentPage = 1
        // if (searchKeyType === 'date') {
        //   q[searchKey] = {
        //     $gte: QueryBuilder.getDate(searchText, 1),
        //     $lt: QueryBuilder.getDate(searchText, 2)
        //   }
        // } else {
        //   q[searchKey] = { $regex: '.*' + searchText + '.*', $options: 'i' }
        // }
        q[base.search.key] = {
          $regex: '.*' + base.search.text + '.*',
          $options: 'i',
        }
      } else {
        q = {}
      }
      if (!_.isEqual(q, local.currentQuery)) {
        local.currentQuery = q
        fetchStaffDetails(
          local.currentQuery,
          local.fields,
          base.data.cursor.currentPage,
          base.data.cursor.perPage,
          local.sort,
        )
      }
    }

    onMounted(() => {
      fetchStaffDetails()
    })

    const fetchStaffDetails = (query, projection, page, limit, sort) => {
      const options = AuthSvc.getOptions()
      StaffDetailSvc.find(options, query, projection, page, limit, sort)
        .then((response) => {
          base.data = response.data
        })
        .catch((error) => {
          console.log('StaffDetailsTable fetch error => ', error)
          common.showErrors(error)
        })
    }

    const createNewStaffDetails = () => {
      return {
        staffId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        position: '',
        dateOfJoining: '',
        salary: 0,
        address: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
      }
    }

    const handleAddStaffDetails = () => {
      base.staffDetailsForm = createNewStaffDetails()
      base.staffDetailsVisible = true
    }

    const handleEditClick = (staffDetail) => {
      const options = AuthSvc.getOptions()
      StaffDetailSvc.findById(options, staffDetail._id)
        .then((response) => {
          base.staffDetailsForm = response.data
          base.staffDetailsVisible = true
        })
        .catch((error) => {
          console.log('StaffDetailsTable handleEditClick error => ', error)
          common.showErrors(error)
        })
    }

    const handleSave = () => {
      staffDetailsRef.value.validate((valid) => {
        if (valid) {
          const options = AuthSvc.getOptions()
          StaffDetailSvc.save(options, base.staffDetailsForm)
            .then((response) => {
              const message =
                response.status === 201
                  ? 'Staff Detail created successfully'
                  : 'Staff Detail updated successfully'
              ElNotification({
                message,
                type: 'success',
              })
              base.staffDetailsVisible = false
              fetchStaffDetails()
            })
            .catch((error) => {
              console.log('StaffDetailsTable handleSave error => ', error)
              common.showErrors(error)
            })
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    const handleCurrentPageChange = (val) => {
      base.data.cursor.currentPage = val
      fetchStaffDetails(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort,
      )
    }

    const handlePageSizeChange = (val) => {
      base.data.cursor.perPage = val
      fetchStaffDetails(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort,
      )
    }

    return {
      base,
      staffDetailsRef,
      Search,
      Plus,
      handleEditClick,
      handleAddStaffDetails,
      handleSave,
      handleCurrentPageChange,
      handlePageSizeChange,
      onSearch,
    }
  },
}
</script>

<style>
.table-max-height {
  min-height: calc(100vh - 205px);
  max-height: calc(100vh - 205px);
}
</style>
