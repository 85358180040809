<template>
  <div class="card">
    <div class="card-header pr-0 pb-0">
      <el-row>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-date-picker
                v-model="base.fromDate"
                type="date"
                placeholder="Pick a day"
                size="large"
                class="w-100"
                format="DD-MM-YYYY"
                @change="handleNoOfDaysChange"
              />
            </el-col>
            <el-col :span="12">
              <el-select
                v-model="base.noOfDays"
                size="large"
                placeholder="No. Of Days"
                @change="handleNoOfDaysChange"
              >
                <el-option label="15" :value="15" />
                <el-option label="30" :value="30" />
                <el-option label="45" :value="45" />
                <el-option label="60" :value="60" />
              </el-select>
            </el-col>
          </el-row>
          <!-- <el-input
            v-model="base.search.text"
            placeholder="Please input"
            class="input-with-select"
          >
            <template #prepend>
              <el-select v-model="base.search.key" placeholder="Select" style="width: 150px">
                <el-option label="Room Number" value="roomNumber" />
              </el-select>
            </template>
            <template #append>
              <el-button :icon="Search" />
            </template>
          </el-input> -->
        </el-col>
        <el-col :span="12">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddReservation"
            >Reservation</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2 calendar-main">
      <!-- <div class="table-responsive p-0 table-max-height">
        <table class="table align-items-center mb-0 mt-3">
          <thead>
            <tr>
              <th
                id="header1"
                class="text-center calendar-header text-uppercase text-xs font-weight-bold opacity-7"
              >
                {{ '' }}
              </th>
              <th
                id="header2"
                class="text-center calendar-header text-uppercase text-xs font-weight-bold opacity-7"
                v-for="mt in base.monthHeadings"
                :key="mt.month"
                :colspan="mt.colspan"
              >
                {{ mt.month }}
              </th>
            </tr>
            <tr>
              <th
                id="header1"
                class="calendar-header text-uppercase text-xs font-weight-bold opacity-7"
              >
                Room Number
              </th>
              <th
                id="header2"
                class="calendar-header text-uppercase text-xs font-weight-bold opacity-7"
                v-for="dt in base.dates"
                :key="dt.date"
              >
                {{ dt.formattedDate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="room in base.rooms" :key="room.roomNumber">
              <td class="calendar-header text-uppercase text-xs font-weight-normal opacity-7">
                <p class="text-sm font-weight-bold mb-0">{{ room.roomNumber }}</p>
              </td>
              <td
                v-for="(dt, index) in base.dates" :key="dt.date"
                :class="getTableRowClass(room, dt, index)"
                @click="handleAddReservation(room)">
                <p class="text-sm font-weight-bold mb-0">{{ '' }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <el-table
        :data="base.reservations"
        row-key="roomNumber"
        border
        default-expand-all
        max-height="calc(100vh - 200px)"
        :cell-class-name="getTableRowClass"
        @cell-click="handleCellClick"
      >
        <el-table-column
          fixed
          prop="roomNumber"
          label="Room Number"
          width="200"
        />
        <el-table-column
          v-for="monthHeading in base.monthHeadings"
          :key="monthHeading.month"
          :label="monthHeading.month"
        >
          <el-table-column
            v-for="dt in monthHeading.dates"
            :key="dt.date"
            width="125"
            :label="dt.formattedDate"
          >
            <template #default="scope">
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="getCellValue(scope.row, dt)"
                placement="top"
              >
                <div
                  style="
                    align-items: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                >
                  {{ getCellValue(scope.row, dt) }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      v-model="base.reservationFormVisible"
      title="Hotel Reservation"
      width="90%"
      destroy-on-close
      align-center
    >
      <el-form
        ref="reservationRef"
        :model="base.reservationForm"
        label-position="top"
        :rules="base.reservationRules"
      >
        <el-row class="mt-2" :gutter="20">
          <el-col :span="24">
            <p class="text-uppercase text-sm">Room Information</p>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Room Number" prop="room.roomNumber">
              <el-select
                v-model="base.reservationForm.room.roomNumber"
                placeholder="Select"
                size="large"
                @change="handleRoomChange"
              >
                <el-option
                  v-for="item in base.rooms"
                  :key="item.roomNumber"
                  :label="item.roomNumber"
                  :value="item.roomNumber"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Check In Date" prop="checkIn">
              <el-date-picker
                v-model="base.reservationForm.checkIn"
                type="date"
                placeholder="Pick a day"
                size="large"
                class="w-100"
                format="DD-MM-YYYY"
                :disabled-date="disabledDateBeforeToday"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Check Out Date" prop="checkOut">
              <el-date-picker
                v-model="base.reservationForm.checkOut"
                type="date"
                placeholder="Pick a day"
                size="large"
                class="w-100"
                format="DD-MM-YYYY"
                :disabled-date="disabledDateBeforeToday"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Number Of Adults">
              <el-input-number
                v-model="base.reservationForm.numberOfAdults"
                :min="1"
                class="w-100"
                controls-position="right"
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Number Of Extra Beds">
              <el-input-number
                v-model="base.reservationForm.numberOfExtraAdults"
                :min="0"
                class="w-100"
                controls-position="right"
                size="large"
                @change="handleExtraAdultsChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Is Checked In?">
              <el-switch
                v-model="base.reservationForm.isCheckedIn"
                size="large"
                active-text="Yes"
                inactive-text="No"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Is Checked Out?">
              <el-switch
                v-model="base.reservationForm.isCheckedOut"
                size="large"
                active-text="Yes"
                inactive-text="No"
                :disabled="!base.reservationForm._id"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <p class="text-uppercase text-sm">Tariff</p>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Actual Price Per Night (Without TAX)">
              <el-input-number
                v-model="base.reservationForm.room.actualPrice"
                class="w-100"
                :controls="false"
                size="large"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Price Per Night">
              <el-input-number
                v-model="base.reservationForm.room.price"
                class="w-100"
                :controls="false"
                size="large"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <p class="text-uppercase text-sm">Guest Information</p>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Title">
              <el-select
                v-model="base.reservationForm.guestDetails.title"
                placeholder="Select"
                size="large"
              >
                <el-option
                  v-for="item in base.titleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Guest Name">
              <el-input
                v-model="base.reservationForm.guestDetails.name"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Mobile Number">
              <el-input
                v-model="base.reservationForm.guestDetails.mobileNumber"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Id Type">
              <el-select
                v-model="base.reservationForm.guestDetails.idType"
                placeholder="Select"
                size="large"
                value-key="name"
              >
                <el-option
                  v-for="item in base.idTypes"
                  :key="item.name"
                  :label="item.displayName"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="ID Number">
              <el-input
                v-model="base.reservationForm.guestDetails.idNumber"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Aadhar Card" class="mb-0">
              <el-upload
                v-model:file-list="fileList"
                class="upload-demo"
                action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                multiple
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :limit="1"
                :on-exceed="handleExceed"
              >
                <el-button type="primary">Click to upload</el-button>
                <template #tip>
                  <div class="el-upload__tip">
                    jpg/png files with a size less than 500KB.
                  </div>
                </template>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <p class="text-uppercase text-sm">Guest Contact Information</p>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Address">
              <el-input
                v-model="base.reservationForm.guestDetails.address"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Country">
              <el-select
                v-model="base.reservationForm.guestDetails.country"
                placeholder="Select"
                size="large"
                value-key="country"
                @change="handleSetStates"
              >
                <el-option
                  v-for="item in base.countries"
                  :key="item.country"
                  :label="item.country"
                  :value="item.country"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="State">
              <el-select
                v-model="base.reservationForm.guestDetails.state"
                placeholder="Select"
                size="large"
              >
                <el-option
                  v-for="item in base.states"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="City">
              <el-input
                v-model="base.reservationForm.guestDetails.city"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="ZIP Code">
              <el-input
                v-model="base.reservationForm.guestDetails.zipCode"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="base.reservationFormVisible = false"
            >Cancel</el-button
          >
          <el-button type="primary" @click="handleSave"> Save </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { Search, Plus } from '@element-plus/icons-vue'
import _ from 'lodash'
import constants from '@/constants'
import RoomSvc from '@/services/room'
import RoomTypeSvc from '@/services/roomType'
import ReservationSvc from '@/services/reservation'
import InvoiceSvc from '@/services/invoice'
import AuthSvc from '@/services/auth'
import calendarUtil from '@/utils/calendarUtil'
import moment from 'moment'
import common from '@/utils/common'
import { ElNotification } from 'element-plus'

export default {
  name: 'ReservationsTable',
  setup() {
    const reservationRef = ref(null)
    const base = reactive({
      reservationFormVisible: false,
      search: {
        text: '',
        key: 'roomNumber',
      },
      reservationForm: {},
      reservations: [],
      rooms: [],
      dates: [],
      monthHeadings: [],
      titleOptions: constants.TITLE_OPTIONS,
      genderOptions: constants.GENDER_OPTIONS,
      countries: constants.COUNTRIES,
      idTypes: constants.ID_TYPES,
      states: [],
      actualPrice: 0,
      dayWiseReservations: [],
      fromDate: moment().startOf('day'),
      noOfDays: 30,
      reservationRules: {
        'room.roomNumber': [{ required: true, message: ' ', trigger: 'blur' }],
        checkIn: [{ required: true, message: ' ', trigger: 'blur' }],
        checkOut: [{ required: true, message: ' ', trigger: 'blur' }],
      },
    })

    onMounted(async () => {
      base.dates = calendarUtil.generateDatesArray()
      const monthHeadings = _.uniqBy(base.dates, 'month')
      monthHeadings.forEach((mt) => {
        const mDates = base.dates.filter((dt) => dt.month === mt.month)
        base.monthHeadings.push({ month: mt.month, dates: mDates })
      })
      console.log('monthHeadings', base.monthHeadings)
      await fetchReservations()
      fetchRoomTypes()
      fetchRooms()
      // handleAddReservation()
    })

    const handleNoOfDaysChange = async () => {
      console.log('base.fromDate', base.fromDate)
      base.dates = calendarUtil.generateDatesArray(base.noOfDays, base.fromDate)
      const monthHeadings = _.uniqBy(base.dates, 'month')
      const headings = []
      monthHeadings.forEach((mt) => {
        const mDates = base.dates.filter((dt) => dt.month === mt.month)
        headings.push({ month: mt.month, dates: mDates })
      })
      base.monthHeadings = headings
      console.log('monthHeadings', base.monthHeadings)
      await fetchReservations()
      fetchRoomTypes()
      fetchRooms()
    }

    const disabledDateBeforeToday = (time) => {
      return time.getTime() < new Date().setHours(0, 0, 0, 0)
    }

    const fetchReservations = async () => {
      const options = AuthSvc.getOptions()
      await ReservationSvc.find(
        options,
        { isCheckedOut: false },
        undefined,
        1,
        1000,
      )
        .then((response) => {
          base.dayWiseReservations = response.data.records
          console.log('base.reservations', response.data.records)
        })
        .catch((error) => {
          console.log('ReservationsTable fetchReservations error => ', error)
          common.showErrors(error)
        })
    }

    const fetchRoomTypes = () => {
      const options = AuthSvc.getOptions()
      RoomTypeSvc.find(options)
        .then((response) => {
          base.roomTypes = response.data.records
        })
        .catch((error) => {
          console.log('ReservationsTable fetchRoomTypes error => ', error)
          common.showErrors(error)
        })
    }

    const fetchRooms = () => {
      const options = AuthSvc.getOptions()
      RoomSvc.find(options)
        .then((response) => {
          base.rooms = response.data.records
          setReservations()
        })
        .catch((error) => {
          console.log('ReservationsTable fetchRooms error => ', error)
          common.showErrors(error)
        })
    }

    const setReservations = () => {
      const reservations = []
      const floors = _.uniq(base.rooms.map((room) => room.floor))
      floors.forEach((floor) => {
        const floorRooms = base.rooms.filter((room) => room.floor === floor)
        const children = []
        floorRooms.forEach((floorRoom) => {
          const roomReservations = base.dayWiseReservations.filter(
            (r) => r.room.roomNumber === floorRoom.roomNumber,
          )
          console.log('roomReservations', roomReservations)
          const child = { roomNumber: floorRoom.roomNumber }
          roomReservations.forEach((r) => {
            child[r.checkIn] = r
          })
          children.push(child)
        })
        reservations.push({
          roomNumber: `Floor ${floor}`,
          isFloor: true,
          children,
        })
      })
      base.reservations = reservations
      console.log('base.reservations', base.reservations)
      console.log('floors', floors)
    }

    const handleCreateNewReservation = (roomNumber, checkIn = '') => {
      const room = base.rooms.find((room) => room.roomNumber === roomNumber)
      return {
        room: {
          roomId: room?.roomId || '',
          roomNumber: room?.roomNumber || '',
          actualPrice: 0,
          taxPercentage: 0,
          price: 0,
        },
        checkIn: checkIn,
        checkOut: '',
        guestDetails: {
          title: '',
          name: '',
          mobileNumber: '',
          idType: '',
          idNumber: '',
          idProofDocument: '',
          address: '',
          city: '',
          state: '',
          zipCode: '',
        },
        numberOfAdults: 2,
        numberOfExtraAdults: 0,
        isCheckedIn: false,
        isCheckedOut: false,
        totalPriceWithoutTax: 0,
        totalPriceWithTax: 0,
      }
    }

    const handleAddReservation = (roomNumber, checkIn) => {
      base.reservationForm = handleCreateNewReservation(roomNumber, checkIn)
      if (base.reservationForm.room.roomId) {
        handleRoomChange()
      }
      base.reservationFormVisible = true
    }

    const handleSave = () => {
      reservationRef.value.validate((valid) => {
        if (valid) {
          const options = AuthSvc.getOptions()
          console.log(JSON.stringify(base.reservationForm))
          InvoiceSvc.save(options, base.reservationForm)
            .then(async (response) => {
              console.log('ReservationsTable handleSave ==>', response.data)
              const message =
                response.status === 201
                  ? 'Reservation created successfully'
                  : 'Reservation updated successfully'
              ElNotification({
                message,
                type: 'success',
              })
              await fetchReservations()
              setReservations()
              base.reservationFormVisible = false
            })
            .catch((error) => {
              console.log('ReservationsTable handleSave error => ', error)
              common.showErrors(error)
            })
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    const handleSetStates = () => {
      if (base.reservationForm.guestDetails.country) {
        const country = constants.COUNTRIES.find(
          (f) => f.country === base.reservationForm.guestDetails.country,
        )
        base.states = country?.states || []
      } else {
        base.states = []
      }
    }

    const handleCellClick = (row, col) => {
      console.log('args', row, col, row[col.rawColumnKey])
      if (!row.isFloor) {
        if (row[col.rawColumnKey]?.invoiceId) {
          const options = AuthSvc.getOptions()
          InvoiceSvc.findById(options, row[col.rawColumnKey]?.invoiceId)
            .then((res) => {
              console.log('res => ', res.data)
              base.reservationForm = res.data
              base.reservationFormVisible = true
            })
            .catch((error) => {
              console.log('ReservationsTable handleCellClicks error => ', error)
              common.showErrors(error)
            })
        } else {
          handleAddReservation(row.roomNumber, col.rawColumnKey)
        }
      }
    }

    const getTableRowClass = (args) => {
      // let rowClass = 'calendar-cell text-uppercase text-xs font-weight-normal opacity-7'
      // if ((index === 0 && room.roomNumber !== '100') || (index === 5 && room.roomNumber !== '101')) {
      //   rowClass += ' room-date-reserved'
      // } else if (dt.isWeekend) {
      //   rowClass += ' calender-weekend-header'
      // }
      // return rowClass
      const colDate = base.dates.find(
        (dt) => dt.formattedDate === args.column.label,
      )
      let rowClass = ''
      if (args.row.roomNumber === '201') {
        // console.log('args', args.column.getColumnIndex(), args.column, base.dates[args.column.getColumnIndex()])
        // console.log('colDate', colDate, args.row)
        // console.log('args.row[colDate.date]', args.row[colDate.date])
      }
      if (!args.row.isFloor) {
        rowClass = 'calendar-cell'
      }
      if (colDate && args.row[colDate.date]) {
        rowClass += ' room-date-reserved'
      }
      //  else if (colDate?.isWeekend) {
      //   rowClass += ' calender-weekend-header'
      // }
      return rowClass
    }

    const handleExtraAdultsChange = () => {
      const selectedRoom = base.rooms.find(
        (room) => room.roomNumber === base.reservationForm.room.roomNumber,
      )
      const roomType = base.roomTypes.find(
        (type) => type._id === selectedRoom.roomType.id,
      )
      base.reservationForm.room.taxPercentage = roomType.taxPercentage
      base.reservationForm.room.actualPrice =
        roomType.ratePerNight +
        base.reservationForm.numberOfExtraAdults * roomType.chargePerExtraAdult
      base.reservationForm.room.price = _.clone(
        base.reservationForm.room.actualPrice,
      )
    }

    const handleRoomChange = () => {
      const selectedRoom = base.rooms.find(
        (room) => room.roomNumber === base.reservationForm.room.roomNumber,
      )
      base.reservationForm.room.roomId = selectedRoom._id
      const roomType = base.roomTypes.find(
        (type) => type._id === selectedRoom?.roomType.id,
      )
      base.reservationForm.room.taxPercentage = roomType?.taxPercentage || 0
      base.reservationForm.room.actualPrice = roomType
        ? roomType.ratePerNight +
          base.reservationForm.numberOfExtraAdults *
            roomType.chargePerExtraAdult
        : 0
      base.reservationForm.room.price = _.clone(
        base.reservationForm.room.actualPrice,
      )
    }

    const getCellValue = (row, dt) => {
      if (!row.isFloor && row[dt.date]) {
        return `${row[dt.date].guestDetails?.title} ${row[dt.date].guestDetails?.name} | ${row[dt.date].totalPriceWithTax}`
      }
      return ''
    }

    return {
      base,
      reservationRef,
      Search,
      Plus,
      handleSetStates,
      handleAddReservation,
      disabledDateBeforeToday,
      handleSave,
      getTableRowClass,
      handleCellClick,
      handleRoomChange,
      fetchRoomTypes,
      handleExtraAdultsChange,
      getCellValue,
      handleNoOfDaysChange,
    }
  },
}
</script>

<style>
.table-max-height {
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
}
.calendar-main {
  margin: 1.5rem;
}
.calendar-row {
  padding: 10px;
}
.calendar-border {
  border: solid;
  border-width: thin;
}
.room-date-reserved {
  /* background-image: linear-gradient(310deg, #d60808, #ff6690); */
  background-color: rgb(64, 201, 112) !important;
  color: white;
  font-weight: 500;
}
.calendar-cell {
  cursor: pointer;
}
.calender-weekend-header {
  background-color: rgb(245, 233, 233) !important;
  color: rgb(51, 51, 51) !important;
}
.calendar-header {
  border-right: 1px solid rgb(192, 192, 192);
  background-color: #f3f3f3 !important;
  color: rgb(51, 51, 51) !important;
}
.el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
</style>
