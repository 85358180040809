<template>
  <div class="card">
    <div class="card-header px-2 pb-0">
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="base.search.text"
            placeholder="Please input"
            clearable
            class="input-with-select"
            @keyup.enter="onSearch"
          >
            <template #prepend>
              <el-select
                v-model="base.search.key"
                placeholder="Select"
                style="width: 125px"
              >
                <el-option label="Room Number" value="roomNumber" />
                <el-option label="Floor" value="floor" />
                <el-option label="Room Type" value="roomType.name" />
              </el-select>
            </template>
            <template #append>
              <el-button :icon="Search" @click="onSearch" />
            </template>
          </el-input>
        </el-col>
        <el-col :span="12" class="pr20">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddRoom"
            >Room</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-table
        ref="tableRef"
        class="table-max-height"
        row-key="email"
        header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
        row-class-name="text-secondary text-xs font-weight-bold"
        :data="base.data.records"
        style="width: 100%"
      >
        <el-table-column prop="roomNumber" label="Room Number" />
        <el-table-column prop="isActive" label="Is Active?">
          <template #default="scope">
            <span v-if="scope.row.isActive" class="badge bg-gradient-success"
              >Yes</span
            >
            <span v-else class="badge bg-gradient-secondary">No</span>
          </template>
        </el-table-column>
        <el-table-column prop="floor" label="Floor" />
        <el-table-column prop="roomType.name" label="Room Type" />
        <el-table-column label="" min-width="75">
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              @click="handleEditClick(scope.row)"
              >Edit</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="base.data.cursor.currentPage"
        small
        class="m-t-10 mb2"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="base.data.cursor.perPage"
        :page-sizes="base.pageSizes"
        :total="parseInt(base.data.cursor.totalRecords)"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      v-model="base.roomFormVisible"
      title="Room Information"
      width="70%"
      destroy-on-close
      align-center
    >
      <el-form
        ref="roomRef"
        :model="base.roomForm"
        label-position="top"
        :rules="base.roomRules"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Room Number" prop="roomNumber">
              <el-input
                v-model="base.roomForm.roomNumber"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Room Type" prop="roomType">
              <el-select
                v-model="base.roomForm.roomType"
                placeholder="Select"
                size="large"
                value-key="name"
              >
                <el-option
                  v-for="item in base.roomTypes"
                  :key="item.name"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Floor" prop="floor">
              <el-input
                v-model="base.roomForm.floor"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Is Active?">
              <el-switch
                v-model="base.roomForm.isActive"
                size="large"
                active-text="Yes"
                inactive-text="No"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="base.roomFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="handleSave"> Confirm </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { Search, Plus } from '@element-plus/icons-vue'
import RoomSvc from '@/services/room'
import RoomTypeSvc from '@/services/roomType'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import _ from 'lodash'
import { ElNotification } from 'element-plus'
import constants from '@/constants'

export default {
  name: 'RoomsTable',
  setup() {
    const handleValidation = (rule, value, callback) => {
      _.isEmpty(value) ? callback(new Error(' ')) : callback()
    }
    const roomRef = ref(null)
    const base = reactive({
      roomFormVisible: false,
      search: {
        text: '',
        key: 'roomNumber',
      },
      roomForm: {
        roomNumber: '',
        floor: '',
        roomType: {},
        isActive: true,
      },
      data: {
        records: [],
        cursor: { currentPage: 0, perPage: 0, totalRecords: 0 },
      },
      pageSizes: constants.DEFAULT_PAGE_SIZES,
      roomTypes: [],
      roomRules: {
        roomNumber: [{ required: true, message: ' ', trigger: 'blur' }],
        floor: [{ required: true, message: ' ', trigger: 'blur' }],
        roomType: [
          {
            required: true,
            validator: handleValidation,
            message: '',
            trigger: 'blur',
          },
        ],
      },
    })

    const local = {
      currentQuery: undefined,
      sort: {},
      fields: undefined,
    }

    const onSearch = () => {
      let q = local.currentQuery
      if (base.search.text) {
        q = {}
        local.currentPage = 1
        // if (searchKeyType === 'date') {
        //   q[searchKey] = {
        //     $gte: QueryBuilder.getDate(searchText, 1),
        //     $lt: QueryBuilder.getDate(searchText, 2)
        //   }
        // } else {
        //   q[searchKey] = { $regex: '.*' + searchText + '.*', $options: 'i' }
        // }
        q[base.search.key] = {
          $regex: '.*' + base.search.text + '.*',
          $options: 'i',
        }
      } else {
        q = {}
      }
      if (!_.isEqual(q, local.currentQuery)) {
        local.currentQuery = q
        fetchRooms(
          local.currentQuery,
          local.fields,
          base.data.cursor.currentPage,
          base.data.cursor.perPage,
          local.sort,
        )
      }
    }

    onMounted(() => {
      fetchRooms()
      fetchRoomTypes()
    })

    const fetchRooms = (query, projection, page, limit, sort) => {
      const options = AuthSvc.getOptions()
      RoomSvc.find(options, query, projection, page, limit, sort)
        .then((response) => {
          base.data = response.data
        })
        .catch((error) => {
          console.log('RoomsTable fetchRooms error => ', error)
          common.showErrors(error)
        })
    }

    const handleSave = () => {
      roomRef.value.validate((valid) => {
        if (valid) {
          const options = AuthSvc.getOptions()
          RoomSvc.save(options, base.roomForm)
            .then((response) => {
              const message =
                response.status === 201
                  ? 'Room created successfully'
                  : 'Room updated successfully'
              ElNotification({
                message,
                type: 'success',
              })
              base.roomFormVisible = false
              fetchRooms()
            })
            .catch((error) => {
              console.log('RoomsTable handleSave error => ', error)
              common.showErrors(error)
            })
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    const fetchRoomTypes = () => {
      const options = AuthSvc.getOptions()
      RoomTypeSvc.find(options, undefined, '_id,name', 1, 1000)
        .then((response) => {
          base.roomTypes = response.data.records.map((roomType) => {
            return {
              name: roomType.name,
              id: roomType._id,
            }
          })
        })
        .catch((error) => {
          console.log('RoomsTable fetchRoomTypes error => ', error)
          common.showErrors(error)
        })
    }

    const handleCreateNewRoom = () => {
      return {
        roomNumber: '',
        floor: '',
        roomType: {},
        isActive: true,
      }
    }

    const handleAddRoom = () => {
      base.roomForm = handleCreateNewRoom()
      base.roomFormVisible = true
    }

    const handleEditClick = (room) => {
      const options = AuthSvc.getOptions()
      RoomSvc.findById(options, room._id)
        .then((response) => {
          base.roomForm = response.data
          base.roomFormVisible = true
        })
        .catch((error) => {
          console.log('RoomsTable handleEditClick error => ', error)
          common.showErrors(error)
        })
    }

    const handleCurrentPageChange = (val) => {
      base.data.cursor.currentPage = val
      fetchRooms(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort,
      )
    }

    const handlePageSizeChange = (val) => {
      base.data.cursor.perPage = val
      fetchRooms(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort,
      )
    }

    return {
      base,
      roomRef,
      Search,
      Plus,
      handleEditClick,
      handleAddRoom,
      handleSave,
      handleCurrentPageChange,
      handlePageSizeChange,
      onSearch,
    }
  },
}
</script>

<style>
.table-max-height {
  min-height: calc(100vh - 205px);
  max-height: calc(100vh - 205px);
}
</style>
